export class weHelp {
    constructor() {
        this.loanStatus = null
        this.forBorrower = "false";
        this.loanTxnId = null;
        this.issue = null;
        this.description = null;
        this.issue = null;
        this.issueType = null;
        this.addedBy = null;
        this.brokerId = null;
        this.fromEmail = null;
        this.userType = null;
        this.emailSent = false;
        this.createdOn = new Date()

    }
    public loanTxnId: string;
    public forBorrower: string;
    public issue: string;
    public description: string;
    public userType: string;
    public issueType: string;
    public addedBy: string;
    public brokerId: string;
    public fromEmail: string;
    public emailSent: boolean
    public createdOn: Date
    public loanStatus: object;
};

class LoanStatus {
    constructor() {
        this.name = null;
        this.id = null
    }
    public name: string;
    public id: number
}