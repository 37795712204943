


import { Component, Vue, Mixins } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import { HollowDotsSpinner } from "epic-spinners";
import { VueEditor } from "vue2-editor";
import stores from "@/store/store";
import { weHelp } from "@/models/we-help.model";
const store: any = stores;
@Component({
  components: { HollowDotsSpinner, VueEditor }
})
export default class WeHelp extends Vue {
  public wemloSupportData = new weHelp();
  public borrowers = [];
  public hideErrorMessage = false;
  public customToolBar = [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }]
  ];
  //-------FOR SELECT OPTIONS OF LOAN STATUS---------------------------------------------------------
  public allLoanStatus = [];
  //-------------------------------------------------------------------------------------------------

  /**************************************************************************************************
   *                   FOR FETCHING ALL BORROWER UNDER CURRENT USER AND LOAN STATUS                  *
   ***************************************************************************************************
   * FUNCTION WORKING PROCESS -----------------------------------------------------------------------
   * Hits the api and get two objects
   * {borrowers , loanStatus}
   * and store it in this.borrowers , this.allLoanStatus
   */
  public async getBorrowerSpecificToUserWithAllLoanStatus() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getBorrowerSpecificToUserWithAllLoanStatus",
        {
          userId:
            this.$store.state.sessionObject.type == "Admin Staff"
              ? this.$store.state.sessionObject.userInfo.brokerId
              : this.$store.state.sessionObject.userId,
          userType: this.$store.state.sessionObject.type
        });
      this.borrowers = response.data.borrowers;
      this.allLoanStatus = response.data.loanStatus;
    } catch (error) {
      console.log(error);
    }
  }

  /**************************************************************************************************
   *                                    ON SUBMITTING THE WE-HELP DATA                               *
   ***************************************************************************************************
   * FUNCTION WORKING PROCESS -----------------------------------------------------------------------
   * Not reviewed yet!
   */
  public async saveWeHelpData() {
    try {
      let formValid = await this.$validator.validateAll("support");
      if (!formValid) {
        return;
      }
      this.$store.state.wemloLoader = true;
      if (this.wemloSupportData.issueType != "Other") {
        this.wemloSupportData.issue = this.wemloSupportData.issueType;
        delete this.wemloSupportData.issueType;
      }

      let response = await Axios.post(
        BASE_API_URL + "broker/saveWeHelpData",
        this.wemloSupportData);

      this.$store.state.wemloLoader = false;
      if (response.data.status == 500)
        this.$snotify.success("Email Sent Successfully");
      this.$router.push({
        path: await this.$getCurrentUserTypePath("ThankyouForMessage"),
        query: { id: response.data.id }
      });
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public resetWemlosupportData(forBorrower) {
    if (forBorrower == "false") {
    }
  }

  mounted() {
    this.wemloSupportData.addedBy = this.$userId;
    this.wemloSupportData.brokerId = this.$brokerId
      ? this.$brokerId
      : this.$userId;
    this.wemloSupportData.userType = this.$userType;
    this.wemloSupportData.fromEmail = this.$store.state.sessionObject.email;
    this.getBorrowerSpecificToUserWithAllLoanStatus();
  }
}
